window.__initForm = function () {
    const forms = $('form.form');
    const EMAIL_REGEXP = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;


    // Form validation
    const formValidation = ($form) => {
        const $inputs = $form.find("input,textarea");
        const errors = [];

        const createErrorLabel = (name, text) =>
            "<label for=" + name + " class='input-error'>" + text + "</label>";

        const createInputChecking = ($parent, conditions) =>
            function () {
                const $errorLabel = $parent.find("label.input-error");

                let valid = true;
                let errorText = "";

                conditions.forEach(({text, func}) => {
                    if (func($(this).val())) {
                        valid = false;
                        errorText = text;
                    }
                });

                if (valid) {
                    $parent.removeClass("error");
                    $errorLabel.fadeOut(200);
                } else {
                    $parent.addClass("error");

                    if (!$errorLabel.get(0)) {
                        $parent.append(createErrorLabel($(this).attr("name"), errorText));
                    } else {
                        $errorLabel.fadeIn(200);
                        $errorLabel.text(errorText);
                    }
                }
            };

        $inputs.each(function () {
            let textError = "";
            const conditions = [];
            const $parent = $(this).parents(".form-group");
            const val = $(this).val();
            const inputName = $(this).attr("name");

            if ($(this).attr("type") === "email") {
                const emailErrorText = "The email address is invalid";
                if (!EMAIL_REGEXP.test(val)) textError = emailErrorText;

                conditions.push({
                    text: emailErrorText,
                    func: (closeVal) => closeVal && !EMAIL_REGEXP.test(closeVal),
                });
            }

            // if ($(this).hasClass("input-phone")) {
            //     const phoneErrorText = "Invalid phone number!";
            //     if (!PHONE_REGEXP.test(val)) textError = phoneErrorText;
            //
            //     conditions.push({
            //         text: phoneErrorText,
            //         func: (closeVal) => closeVal && !PHONE_REGEXP.test(closeVal),
            //     });
            // }

            if ($parent.hasClass("required")) {
                const requiredErrorText = "This is a required field";
                if (val.length < 2) textError = requiredErrorText;

                conditions.push({
                    text: requiredErrorText,
                    func: (closeVal) => !closeVal || closeVal.length < 2,
                });
            }

            $(this).keyup(createInputChecking($parent, conditions));

            if (textError) {
                $parent
                    .addClass("error")
                    .append(createErrorLabel(inputName, textError));
                errors.push({name: inputName, text: textError});
            }
        });

        return errors.length > 0 ? errors : null;
    };

    // Submit form
    forms.on("submit", function (e) {
        e.preventDefault();
        const thisForm = $(this);
        const errors = formValidation(thisForm);

        if (!errors) {
            let formData = new FormData();
            formData.append('action', 'contact_form');

            thisForm.find('input, textarea').each(function () {
                let name = $(this).attr('name');
                let value = $(this).val();

                formData.append(name, value);
            });

            new Response(formData).text().then(console.log);

            const ajax_url = "http://dex.ternala.com/wp-admin/admin-ajax.php";
            $.ajaxSetup({
                processData: false,
                contentType: false,
                // beforeSend: function () {
                //     thisForm.addClass("loaded");
                // },
            });

            $.post(ajax_url, formData)
                .done(function (e) {
                    //thisForm.removeClass("loaded").addClass("success");
                    thisForm.addClass("form-success");

                    setTimeout(function () {
                        thisForm.removeClass("form-success");
                        thisForm.find('input, textarea').val('');
                    }, 3000);
                })
                .fail(function (error) {
                    console.error(error);
                    //thisForm.removeClass("loaded").addClass("error");
                    thisForm.addClass("form-error");

                    setTimeout(function () {
                        thisForm.removeClass("form-error");
                    }, 3000);
                });
        }
        return false;
    });
}
