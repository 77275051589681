document.addEventListener("DOMContentLoaded", function() {
    const $window = $(window);

    // Function for reacting on window resize
    const reactOnResize = (reactionWidth, cb) => {
        let isSmaller = $window.width() < reactionWidth;

        cb(isSmaller);
        $window.on("resize", function () {
            const width = $(this).width();

            if (isSmaller && width > reactionWidth) {
                isSmaller = false;
                cb(isSmaller);
            } else if (!isSmaller && width < reactionWidth) {
                isSmaller = true;
                cb(isSmaller);
            }
        });
    };

    // // Init js modules
    window.commonComponents();
    setTimeout(() => {
        window.__initForm();
    }, 0);

    let c, currentScrollTop = 0;
    const navbar = $('.navbar');
    $window.scroll(function () {
        const scroll = $(window).scrollTop();
        currentScrollTop = scroll;

        if (c < currentScrollTop && scroll > 100) {
            navbar.removeClass('scrolledDown');
            navbar.addClass('scrolledUp');
        } else if (c > currentScrollTop && !(scroll <= 100)) {
            navbar.removeClass('scrolledUp');
            navbar.addClass('scrolledDown');
        } else {
            navbar.removeClass('scrolledDown');
        }

        c = currentScrollTop;
    });

    // Mobile menu
    const $navigationMenu = $(".navbar .navbar-menu");
    $(".navbar .burger").click(function () {
        let navbarHeight = navbar.innerHeight() + 'px';

        $(this).toggleClass("burger-active");
        $(".navbar").toggleClass("navbar-menu-active");
        $('body').toggleClass('overflow-hidden');
        $navigationMenu.css('top', navbarHeight);
    });

    // Market tab
    const marketTabs = $('.market-tab');
    const marketTab = marketTabs.find('.tab');
    const marketTabItem = $('.tab-item');
    const marketTabContainer = marketTabs.find('.tab-content');
    let tabCycle;

    function changeTab(tab, tabItem, tabContent) {
        if ($window.width() > 768) {
            const activeTabItem = tab.filter('.active');
            const next = activeTabItem.next().length ? activeTabItem.next() : tab.filter(':first-child');
            tab.removeClass('active');
            tabContent.empty();
            next.find(tabItem).clone().appendTo(tabContent);
            next.addClass('active');
        }
    }

    window.reactOnResize(769, (isSmaller) => {
        if (!isSmaller) {
            marketTab.find('.tab-title').unbind('click');
            clearInterval(tabCycle);
            marketTabs.find(marketTabItem).attr('style', '');
            marketTabContainer.empty();
            marketTab.removeClass('active');
            marketTab.filter(':first-of-type').addClass('active');
            marketTab.filter(':first-of-type').find(marketTabItem).clone().appendTo(marketTabContainer);
            tabCycle = setInterval(changeTab, 5000, marketTab, marketTabItem, marketTabContainer);

            $(function () {
                marketTab.unbind('click').bind('click', function (e) {
                    e.preventDefault();
                    clearInterval(tabCycle);
                    marketTab.removeClass('active');
                    marketTabContainer.empty();
                    $(this).find(marketTabItem).clone().appendTo(marketTabContainer);
                    $(this).addClass('active');
                    tabCycle = setInterval(changeTab, 5000, marketTab, marketTabItem, marketTabContainer);
                });
            });

            marketTabContainer.hover(function () {
                clearInterval(tabCycle);
                marketTabs.find('.tab.active .tab-line').addClass('paused');
            }, function () {
                tabCycle = setInterval(changeTab, 5000, marketTab, marketTabItem, marketTabContainer);
                marketTabs.find('.tab.active .tab-line').removeClass('paused');
            });
        } else {
            marketTab.unbind('click');
            clearInterval(tabCycle);
            marketTab.removeClass('active');
            marketTabs.find(marketTabItem).slideUp();
            marketTab.filter(':first-of-type').addClass('active');
            marketTab.filter(':first-of-type').find('.tab-item').slideDown();

            marketTab.find('.tab-title').unbind('click').bind('click', function () {
                if ($window.width() <= 768) {
                    marketTab.removeClass('active');
                    marketTabItem.slideUp();
                    $(this).closest('.tab').addClass('active');
                    $(this).closest('.tab').find('.tab-item').slideDown();
                }
            });
        }
    });

    // Caring tab
    const caringTabs = $('.caring-tab');
    const caringTab = caringTabs.find('.tab');
    const caringTabItem = $('.tab-item');
    const caringTabContainer = caringTabs.find('.tab-content');

    window.reactOnResize(993, (isSmaller) => {
        if (!isSmaller) {
            caringTab.find('.tab-title').unbind('click');
            caringTabs.find(caringTabItem).attr('style', '');
            caringTabContainer.empty();
            caringTab.removeClass('active');
            caringTab.filter(':first-of-type').addClass('active');
            caringTab.filter(':first-of-type').find(caringTabItem).clone().appendTo(caringTabContainer);

            $(function () {
                caringTab.unbind('click').bind('click', function (e) {
                    e.preventDefault();
                    caringTab.removeClass('active');
                    caringTabContainer.empty();
                    $(this).find(caringTabItem).clone().appendTo(caringTabContainer);
                    $(this).addClass('active');
                });
            });
        } else {
            caringTab.unbind('click');
            caringTab.removeClass('active');
            caringTabs.find(caringTabItem).slideUp();
            caringTab.filter(':first-of-type').addClass('active');
            caringTab.filter(':first-of-type').find('.tab-item').slideDown();

            caringTab.find('.tab-title').unbind('click').bind('click', function () {
                caringTab.removeClass('active');
                caringTabItem.slideUp();
                $(this).closest('.tab').addClass('active');
                $(this).closest('.tab').find('.tab-item').slideDown();
            });
        }
    });


    // Transaction slider
    const transactionSlider = new Swiper('.transactions-slider', {
        slidesPerView: 'auto',
        slidesPerGroup: 1,
        allowTouchMove: true,
        navigation: {
            nextEl: '.swiper-button-next',
            prevEl: '.swiper-button-prev',
        },
        scrollbar: {
            el: '.swiper-scrollbar',
            draggable: true,
        },
        breakpoints: {
            320: {
                spaceBetween: 40,
            },
            769: {
                spaceBetween: 70,
            },
        }
    });

    // Contact form
    const $contactForm = $('.contact-form');
    const input = $contactForm.find('.input, .textarea');

    function checkInputValue() {
        input.each(function () {
            if (!$(this).val()) {
                $(this).closest('.form-group').removeClass('active');
            } else {
                $(this).closest('.form-group').addClass('active');
            }
        });
    }

    input.on('input, blur', function () {
        checkInputValue();
    });

    input.on('click', function () {
        $(this).closest('.form-group').addClass('active');
    });


    reactOnResize(769, (isSmaller) => {
        if (!isSmaller) {
            AOS.init({
                duration: 800,
                offset: 10,
            });
        } else {
            AOS.init({
                disable: true,
            });
        }
    });

    $(".aos-list").each(function () {
        $(this)
            .children()
            .each(function (index) {
                $(this).attr("data-aos-duration", index * 250 + 600);
                $(this).attr("data-aos-delay", index * 250);
            });
    });

    // Parallax
    $('[data-type="parallax"]').each(function () {
        const $currentParallax = $(this);
        let eTop = $currentParallax.offset().top;

        $(window).on('scroll', function () {
            reactOnResize(769, (isSmaller) => {
                if (!isSmaller) {
                    const yPos = ((eTop - $(window).scrollTop()) / $currentParallax.data('speed'));
                    if(yPos > 0 && yPos < 70) {
                        const coords = yPos + 'px';
                        $currentParallax.css({marginBottom: coords});
                    }
                } else {
                    $currentParallax.attr('style', '');
                }
            });
        });
    });
});
